<template>
  <div :class="$style.home">
    <div id="containerManage" :class="$style.map" />
    <div v-if="loading" :class="$style.loading">
      <a-spin :class="$style.spin" />
    </div>
    <div :class="$style.group">
      <div v-if="!groupShow" :class="$style.btn" @click="onGroupShow(true)">
        <i class="ri-menu-2-line" />
      </div>
      <div v-else :class="$style.btnClose" @click="onGroupShow(false)">
        <i class="ri-close-line" />
      </div>
      <div v-if="groupShow" :class="$style.groupBox">
        <div v-for="item in groupData" :key="item.id" :class="$style.groupItem">
          <img v-if="item.id !== 'all'" :src="item.icon" />
          <i v-else :class="item.icon" />
          <span class="overflow" :class="$style.groupItemName">{{ item.name }}</span>
          <!-- <a-radio :checked="item.id === groupId" @change="onGroupChange(item.id)" /> -->
          <a-checkbox :checked="groupId.indexOf(item.id) !== -1" @change="onGroupChange(item.id)" />
        </div>
      </div>
    </div>
    <div :class="$style.content">
      <div :class="$style.search">
        <a-input v-model="search.keyword" placeholder="搜索文化点" @pressEnter="onSearch">
          <i slot="suffix" class="ri-search-2-line" @click="onSearch" />
        </a-input>
      </div>
      <div v-if="search.show && !detailShow" :class="$style.box">
        <div :class="$style.result">
          搜索到"
          <span class="primary">{{ search.keyword }}</span>
          "的结果
        </div>
        <div v-for="item in data" :key="item.id" :class="$style.resultBox" @click="onChoose(item)">
          <img :class="$style.resultImg" :src="item.cover.cover" />
          <div :class="$style.resultText">
            <p class="overflow" :class="$style.resultName">
              {{ item.name }}
            </p>
            <p class="overflow" :class="$style.resultDesc">{{ item.name }}</p>
            <span class="tag overflow" :class="$style.resultType">{{ item.group.name || '默认分组' }}</span>
          </div>
        </div>
        <div v-if="!data.length" class="nodata mt-100">
          <img :src="oss + '/manage/media/state/no_comment.png'" />
          <p>暂无数据</p>
        </div>
      </div>
      <div v-if="search.show && detailShow" :class="$style.detail">
        <img :class="$style.cover" :src="detailData.cover.cover" />
        <div :class="$style.title">
          <div :class="$style.name" class="overflow">
            {{ detailData.name }}
          </div>
          <div :class="$style.location">
            {{ detailData.location }}
          </div>
          <span class="tag overflow" :class="$style.detailGroup">{{ detailData.group_name }}</span>
        </div>
        <div :class="$style.line" />
        <div :class="$style.summary">{{ detailData.summary }}</div>
        <div v-if="!sourceDetail" :class="$style.detailClose" @click="onCloseDetail(false)">
          <i class="ri-arrow-left-line" />
        </div>
        <div v-else :class="$style.detailClose" @click="onCloseDetail(true)">
          <i class="ri-close-line" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { resourceMapService } from '@/service'

const service = new resourceMapService()
let AMap = window.AMap
let getAmap = () => {
  AMap = window.AMap
  if (!AMap) {
    setTimeout(() => {
      getAmap()
    }, 100)
  }
}
getAmap()

export default {
  name: 'Home',
  data() {
    return {
      map: null,
      params: {
        top_left: '',
        bottom_right: '',
        poc_id: '',
      },
      search: {
        keyword: null,
        page: 1,
        page_size: 20,
        show: false,
      },
      activeId: null, // 地图正在显示详情的文化点id
      chooseId: null, // 左侧选择id
      data: [], // 左侧搜索数据
      mapData: [], // 地图显示marker数据
      loading: false,
      set: {}, // 用户配置数据
      detailData: {}, //详情数据
      groupData: [],
      groupId: [],
      groupShow: false,
      detailShow: false,
      sourceDetail: false, // 地图进入的详情
    }
  },
  created() {
    this.getSet()
    this.getGroup()
  },
  methods: {
    onGroupShow(_type) {
      this.groupShow = _type
    },
    onGroupChange(_id) {
      // 处理下联动逻辑  很烦
      if (_id === 'all') {
        if (this.groupId.indexOf('all') !== -1) {
          this.groupId = []
        } else {
          this.groupId = []
          this.groupData.map(item => {
            this.groupId.push(item.id)
          })
        }
      } else {
        if (this.groupId.indexOf(_id) !== -1) {
          this.groupId.splice(this.groupId.indexOf(_id), 1)
          if (this.groupId.length !== this.groupData.length && this.groupId.indexOf('all') !== -1) {
            this.groupId.splice(this.groupId.indexOf('all'), 1)
          }
        } else {
          this.groupId.push(_id)
          if (this.groupId.length === this.groupData.length - 1) {
            this.groupId.push('all')
          }
        }
      }

      this.onFilterMapData()
    },
    onCloseDetail(type) {
      if (type) {
        this.detailShow = false
        this.search.show = false
      } else {
        this.detailShow = false
      }
    },
    onFilterMapData() {
      this.map.clearMap()
      let data = []
      if (this.groupId.length === 0 || this.groupId.length === this.groupData.length) {
        data = this.mapData
      } else {
        data = this.mapData.filter(item => this.groupId.indexOf(item.group.id) !== -1)
      }
      data.map(item => {
        let markerContent = `
          <div class="map-pop">
            <img id="target" class="map-img" src="${item.cover.cover}" >
            <div class="map-box" id="${item.id}" style="display: none">
              <div class="map-flex">
                <img class="map-box-img" src="${item.cover.cover}" >
                <div class="map-box-text">
                    <div class="map-box-name">${item.name}</div>
                    <p class="map-box-desc">${item.summary}</p>
                    <span class="map-box-type tag">${item.group.name || '默认分组'}</span>
                </div>
              </div>
              <div class="map-box-delta"></div>
            </div>
          </div>`
        let position = new AMap.LngLat(item.longitude, item.latitude)
        let marker = new AMap.Marker({
          position: position,
          content: markerContent,
          //   offset: new AMap.Pixel(-13, -30),
        })
        marker.on('click', e => {
          this.showPop(item.id, e)
        })
        this.map.add(marker)
      })
    },
    async getGroup() {
      const { data } = await service.getGroup()
      this.groupData = [
        {
          name: '全部文化',
          icon: 'ri-apps-line',
          id: 'all',
        },
      ].concat(data)
    },
    onSearch() {
      this.detailShow = false
      if (this.search.keyword) {
        this.search.show = true
        this.getList()
      } else {
        this.search.show = false
      }
      //   this.getList()
    },
    async getSet() {
      const { data } = await service.getSet()
      this.set = data
      this.initMap()
    },
    onChoose(item) {
      this.chooseId = item.id
      this.params.poc_id = item.id
      this.sourceDetail = false
      this.map.panTo([item.longitude, item.latitude])
      this.mapBounds()
      this.getMap()
      this.onDetail(item.id)
    },
    async getList() {
      try {
        const { data } = await service.getList({
          ...this.search,
        })
        this.data = data.record
      } catch (error) {}
    },
    async getMap() {
      try {
        this.groupId = []
        this.loading = true
        this.activeId = null
        const { data } = await service.getMap({
          ...this.params,
          precision: String(parseInt(this.map.getZoom() - 4)),
        })
        this.mapData = data
        this.map.clearMap()
        data.map(item => {
          let markerContent = `
          <div class="map-pop">
            <img id="target" class="map-img" src="${item.cover.cover}" >
            <div class="map-box" id="${item.id}" style="display: none">
              <div class="map-flex">
                <img class="map-box-img" src="${item.cover.cover}" >
                <div class="map-box-text">
                    <div class="map-box-name">${item.name}</div>
                    <p class="map-box-desc">${item.summary}</p>
                    <span class="map-box-type tag">${item.group.name || '默认分组'}</span>
                </div>
              </div>
              <div class="map-box-delta"></div>
            </div>
          </div>`
          let position = new AMap.LngLat(item.longitude, item.latitude)
          let marker = new AMap.Marker({
            position: position,
            content: markerContent,
            //   offset: new AMap.Pixel(-13, -30),
          })
          marker.on('click', e => {
            this.showPop(item.id, e)
          })
          this.map.add(marker)
        })
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    initMap() {
      this.map = new AMap.Map('containerManage', {
        mapStyle: 'amap://styles/' + this.set.style, // https://lbs.amap.com/demo/jsapi-v2/example/personalized-map/set-theme-style
        zoom: this.set.level, //级别
        zooms: [4, 15],
        center: this.set.center_longitude
          ? [this.set.center_longitude, this.set.center_latitude]
          : [104.065681, 30.653442], //中心点坐标
        viewMode: '3D', //使用3D视图
      })
      this.map.on('dragend', this.dragend) // 拖拽结束
      this.map.on('zoomend', this.zoomend) // 缩放结束
      this.map.on('complete', () => {
        this.mapBounds()
        this.getMap()
      })
    },
    mapBounds() {
      let bounds = this.map.getBounds().bounds
      this.params.top_left = {
        longitude: bounds[0].lng,
        latitude: bounds[0].lat,
      }
      this.params.bottom_right = {
        longitude: bounds[2].lng,
        latitude: bounds[2].lat,
      }
    },
    showPop(id, e) {
      if (e.originEvent.target.id === 'target') {
        this.mapData.map(item => {
          let otherDom = document.getElementById(item.id)
          if (otherDom) {
            otherDom.style.display = 'none'
            otherDom.parentNode.parentNode.parentNode.style.zIndex = 100
          }
        })
        if (this.activeId === id) {
          this.activeId = null
          return
        }
        this.activeId = id
        let dom = document.getElementById(id)
        dom.style.display = 'block'
        dom.parentNode.parentNode.parentNode.style.zIndex = 101
        this.onDetail(id)
        this.sourceDetail = true
      }
    },
    async onDetail(id) {
      const { data } = await service.getDetail({
        poc_id: id,
      })
      this.search.show = true
      this.detailShow = true
      this.detailData = data
    },
    dragend() {
      this.getMap()
    },
    zoomend() {
      this.getMap()
    },
  },
}
</script>

<style lang="less" module>
.home {
  position: relative;

  .loading {
    position: relative;
    width: 100%;
    height: calc(100vh - 152px);
    background-color: rgba(256, 256, 256, 0.3);

    .spin {
      position: absolute;
      top: 50%;
      left: 50%;
      text-align: center;
      transform: translate(-50%, -50%);
    }
  }

  .content {
    position: absolute;
    top: 40px;
    left: 40px;
    width: 400px;

    .search {
      width: 100%;
      height: 40px;
      background-color: #fff;
      border-radius: 6px;
      line-height: 40px;

      :global {
        .ant-input {
          border: none;
        }

        .ant-input:focus {
          border: none;
          box-shadow: none !important;
        }

        i {
          font-size: 20px;
          color: #000;
        }
      }
    }

    .box {
      background-color: #fff;
      margin-top: 20px;
      border-radius: 6px;
      height: calc(100vh - 250px);
      overflow-y: auto;
      padding: 20px;
      overflow-y: auto;

      .result {
        font-size: 12px;
        color: #000;
        margin-bottom: 14px;
      }

      .resultBox {
        width: 100%;
        height: 80px;
        padding: 10px;
        display: flex;
        margin-bottom: 2px;
        cursor: pointer;
        border-radius: 6px;
        &:hover {
          background: #fafafa;
        }
        .resultImg {
          width: 60px;
          height: 60px;
          object-fit: cover;
          margin-right: 10px;
          border-radius: 6px;
        }

        .resultText {
          position: relative;
          flex: 1;
        }

        .resultDesc {
          font-size: 12px;
          color: #5c5c5c;
          margin-bottom: 0;
        }

        .resultName {
          font-size: 18px;
          color: #000;
          margin-bottom: 10px;
          width: 200px;
        }

        .resultType {
          position: absolute;
          top: 0;
          right: 0;
          max-width: 74px;
        }
      }
    }

    .detail {
      background-color: #fff;
      margin-top: 20px;
      border-radius: 6px;
      height: calc(100vh - 250px);
      overflow-y: auto;
      padding: 20px;
      overflow-y: auto;
      position: relative;

      .line {
        width: 100%;
        height: 1px;
        border-top: 1px solid #eee;
        margin: 14px 0;
      }

      .cover {
        width: 100%;
        height: 240px;
        object-fit: cover;
        border-radius: 6px;
        margin-bottom: 20px;
      }

      .title {
        position: relative;

        .name {
          font-size: 18px;
          color: #000;
          width: 285px;
          margin-bottom: 10px;
        }

        .location {
          font-size: 12px;
          color: #5c5c5c;
        }

        .detail-group {
          position: absolute;
          top: 0;
          right: 0;
          max-width: 70px;
        }
      }

      .summary {
        font-size: 12px;
        color: #5c5c5c;
      }

      .detail-close {
        position: absolute;
        left: 30px;
        top: 30px;
        width: 36px;
        height: 36px;
        background: #fff;
        border-radius: 100%;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        font-size: 20px;
      }
    }
  }

  .group {
    position: absolute;
    right: 40px;
    top: 40px;
    width: 140px;

    .btn {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: #fff;
      float: right;
      text-align: center;
      line-height: 40px;

      i {
        font-size: 20px;
        color: #000;
      }
    }

    .btn-close {
      width: 40px;
      height: 40px;
      border-radius: 100%;
      background: @primary-color;
      float: right;
      text-align: center;
      line-height: 40px;

      i {
        font-size: 20px;
        color: #fff;
      }
    }

    .group-box {
      margin-top: 40px;
      height: 500px;
      overflow: auto;

      .group-item {
        width: 100%;
        height: 40px;
        background: #fff;
        border-radius: 6px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        margin-top: 10px;

        img {
          width: 24px;
          height: 24px;
          border-radius: 100%;
          object-fit: cover;
          margin-right: 6px;
        }

        i {
          font-size: 18px;
          color: #000;
          margin-right: 6px;
        }

        .group-item-name {
          flex: 1;
        }
      }
    }
  }
}
</style>

<style lang="less">
#containerManage {
  width: calc(100% - 40px);
  height: calc(100% - 40px);
  position: absolute;
  top: 20px;
  left: 20px;
}

.amap-marker-content {
  white-space: inherit !important;
}

.map-pop {
  position: relative;
  width: 38px;
  height: 38px;

  .map-img {
    width: 38px;
    height: 38px;
    border-radius: 100%;
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
  }

  .map-box {
    width: 340px;
    height: 92px;
    background-color: #fff;
    border-radius: 6px;
    padding: 10px;
    position: absolute;
    top: -103px;
    left: -156px;

    .map-flex {
      width: 100%;
      height: 100%;
      display: flex;
    }

    .map-box-img {
      width: 72px;
      height: 72px;
      object-fit: cover;
      border-radius: 6px;
      margin-right: 10px;
    }

    .map-box-text {
      flex: 1;
    }

    .map-box-name {
      width: 178px;
      font-size: 18px;
      color: #000;
      margin-bottom: 10px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .map-box-desc {
      width: 238px;
      font-size: 12px;
      color: #5c5c5c;
      margin-bottom: 0;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    .map-box-type {
      position: absolute;
      right: 10px;
      top: 10px;
      max-width: 80px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }

    .map-box-delta {
      width: 0;
      height: 0;
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-top: 6px solid #fff;
      position: absolute;
      bottom: -6px;
      left: 50%;
    }
  }
}
</style>
